import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit'
import { showNotification } from 'utils/showNotification'

const serverErrorToString = (error: any): string => {
  return error?.data?.detail ? error.data.detail : 'Something went wrong'
}

export const errorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    showNotification(serverErrorToString(action?.payload || 'Error'), 'error')
  }
  return next(action)
}