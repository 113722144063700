import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box/Box'
import Drawer from '@mui/material/Drawer/Drawer'
import IconButton from '@mui/material/IconButton/IconButton'
import Link from '@mui/material/Link/Link'
import Typography from '@mui/material/Typography/Typography'

import { NavMenuItemType } from 'types/NavMenuItem'

import scrollToComponent from 'utils/scrollToComponent'

import KeycloakService from 'services/KeycloakService'

import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'

import Button from 'components/common/Button'
import Logo from 'components/common/Logo'
import { CookiePolicyModal } from 'components/policies/CookiePolicyModal'
import { PrivacyPolicyModal } from 'components/policies/PrivacyPolicyModal'
import { TermsAndConditionsModal } from 'components/policies/TermsAndConditionsModal'

import NavMenuItem from '../PublicAppBar/NavMenuItem'
import { socialLinks } from '../PublicFooter/menus'

interface Props {
  isDrawerOpen: boolean
  handleDrawerOpen: (v: boolean) => void
  menuItems: NavMenuItemType[]
  appBarPositionTop: boolean
  isLanding: boolean
  activeSection: string
}

export const MobileAppBar = ({
  handleDrawerOpen,
  isDrawerOpen,
  menuItems,
  appBarPositionTop,
  isLanding,
  activeSection,
}: Props) => {
  const [modal, setModal] = useState<'PRIVACY' | 'TERMS' | 'COOKIE' | 'DESIGN' | ''>('')
  const location = useLocation()
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '1.5rem',
        }}
      >
        <Link href="/welcome" sx={{ textDecoration: 'none', lineHeight: 1 }}>
          <Logo white={appBarPositionTop && isLanding} />
        </Link>
        <IconButton
          aria-label="open drawer"
          onClick={() => handleDrawerOpen(!isDrawerOpen)}
          edge="start"
          sx={{
            color: appBarPositionTop
              ? location.pathname.includes('registration') || location.pathname.includes('invite')
                ? '#000'
                : '#fff'
              : '#000',
            p: 0,
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Drawer
        sx={{
          width: isDrawerOpen ? '21.5rem' : 0,
          flexShrink: 0,
          transition: '0.3s',
          '& .MuiDrawer-paper': {
            width: isDrawerOpen ? '21.5rem' : 0,
            boxSizing: 'border-box',
            border: 'none',
            overflow: 'hidden',
            backgroundColor: '#fff',
            transition: '0.3s',
          },
          boxShadow: '-2px -5px',
        }}
        anchor="right"
        variant="temporary"
        open={isDrawerOpen}
        onClick={() => handleDrawerOpen(false)}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1.25rem',
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', p: '1.5rem' }}>
            <CloseIcon
              style={{ minWidth: '2rem', minHeight: '2rem', cursor: 'pointer' }}
              onClick={() => handleDrawerOpen(!isDrawerOpen)}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: '1.5rem',
              gap: '1.5rem',
            }}
          >
            {menuItems.map((item) => {
              return (
                <NavMenuItem key={item.text} item={item} current={activeSection} color="#262626" />
              )
            })}
          </Box>
          {location.pathname.includes('welcome') || location.pathname.includes('sellers') ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: '1.5rem',
                gap: '1rem',
              }}
            >
              <Button
                onClick={() => {
                  window.location.href = 'https://calendly.com/shopiview_waitlist/30min'
                }}
                endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
                sx={{
                  color: '#FFFFFF',
                  borderRadius: '1.5rem',
                  width: '100%',
                }}
              >
                Schedule call
              </Button>
              <Button
                onClick={(event) => {
                  scrollToComponent(event, 'wait_list')
                }}
                endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
                sx={{
                  color: '#FFFFFF',
                  borderRadius: '1.5rem',
                  width: '100%',
                }}
              >
                Join waitlist
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: '1.5rem',
                gap: '1rem',
              }}
            >
              {/* <Button
                onClick={() => KeycloakService.doLogin()}
                endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
                sx={{
                  color: '#FFFFFF',
                  borderRadius: '1.5rem',
                  width: '100%',
                }}
              >
                Sign In
              </Button> */}
              {/* <Button
                href="/seller-registration"
                sx={{
                  borderRadius: 6,
                  width: '100%',
                }}
                endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
              >
                Sign Up
              </Button> */}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              gap: '1.5rem',
              flexDirection: 'column',
              p: '1.5rem',
            }}
          >
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontFamily: '"Nunito Sans", sans-serif',
                '&:hover': { color: '#a5a5a5' },
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => setModal('PRIVACY')}
            >
              Privacy Policy
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontFamily: '"Nunito Sans", sans-serif',
                '&:hover': { color: '#a5a5a5' },
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => setModal('TERMS')}
            >
              Terms of Service
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontFamily: '"Nunito Sans", sans-serif',
                '&:hover': { color: '#a5a5a5' },
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => setModal('COOKIE')}
            >
              Cookies Settings
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '0.75rem', alignItems: 'center', px: '1.5rem' }}>
            {socialLinks.map((social, index) => (
              <Link
                key={index}
                href={social.link}
                sx={{ '&:hover': { color: '#ffffff' }, display: 'inline-flex' }}
              >
                {social.icon}
              </Link>
            ))}
          </Box>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 400,
              fontFamily: '"Nunito Sans", sans-serif',
              px: '1.5rem',
            }}
          >
            ShopiView © 2024. All rights reserved.
          </Typography>
        </Box>
      </Drawer>
      {modal === 'COOKIE' && <CookiePolicyModal onClose={() => setModal('')} />}
      {modal === 'TERMS' && <TermsAndConditionsModal onClose={() => setModal('')} />}
      {modal === 'PRIVACY' && <PrivacyPolicyModal onClose={() => setModal('')} />}
    </>
  )
}
