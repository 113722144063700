import { FC } from 'react'
import { Link } from 'react-router-dom'

import Typography from '@mui/material/Typography'

import { NavMenuItemType } from 'types/NavMenuItem'

import scrollToComponent from 'utils/scrollToComponent'

interface MenuItemProps {
  item: NavMenuItemType
  current: string
  color: string
}

const NavMenuItem: FC<MenuItemProps> = ({ item, current, color }) => {
  const { link, text, scrollToComponentWithId } = item

  return (
    <Link
      to={link}
      style={{
        textDecoration: 'none',

        color: color,
        padding: '0 0.25rem',
        fontWeight: '900',
        transition: '0.3s',
      }}
    >
      <Typography
        onClick={
          scrollToComponentWithId
            ? (event) => scrollToComponent(event, scrollToComponentWithId)
            : undefined
        }
        fontWeight={item.scrollToComponentWithId === current ? '700' : '600'}
        sx={{
          width: 'fit-content',
          px: '0.2rem',
          borderBottom:
            item.scrollToComponentWithId === current
              ? `2px ${color} solid`
              : '2px transparent solid',
        }}
      >
        {' '}
        {text}{' '}
      </Typography>
    </Link>
  )
}

export default NavMenuItem
