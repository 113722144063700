import { FC } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

import { Box, SxProps } from '@mui/material'

interface Props {
  src: string
  playerProps?: ReactPlayerProps
  sx?: SxProps
}

const VideoPlayer: FC<Props> = ({ src, playerProps, sx }) => {
  return (
    <Box sx={{ width: '100%', height: '100%', aspectRatio: '16/9', ...sx }}>
      <ReactPlayer width="100%" height="100%" controls url={src} {...playerProps} />
    </Box>
  )
}

export default VideoPlayer
