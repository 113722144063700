import { ReactNode } from 'react'

import { Link, SxProps } from '@mui/material'

interface Props {
  children: ReactNode
  href: string
  sx?: SxProps
}

export const ExternalLink = ({ children, href, sx }: Props) => {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      sx={{ color: 'draft.main', textDecoration: 'underline', ...sx }}
    >
      {children}
    </Link>
  )
}
