import { FC, useState } from 'react'

import MailIcon from '@mui/icons-material/Mail'
import { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import InputBase from '@mui/material/InputBase'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'
import useMediaQuery from '@mui/material/useMediaQuery'

import Button from 'components/common/Button'
import { ExternalLink } from 'components/common/ExternalLink'
import Logo from 'components/common/Logo'
import { CookiePolicyModal } from 'components/policies/CookiePolicyModal'
import { PrivacyPolicyModal } from 'components/policies/PrivacyPolicyModal'
import { TermsAndConditionsModal } from 'components/policies/TermsAndConditionsModal'

import { footerMenu, socialLinks } from './menus'

const styles = {
  link: {
    fontSize: '0.875rem',
    fontFamily: '"Inter", sans-serif',
    '&:hover': { color: '#FFFFFF' },
    cursor: 'pointer',
  },
}

const PublicFooter: FC = () => {
  const [modal, setModal] = useState<'PRIVACY' | 'TERMS' | 'COOKIE' | 'DESIGN' | ''>('')
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  return (
    <Box
      component="footer"
      sx={{ py: '4rem', bgcolor: '#262626', display: 'flex', color: '#FFFFFF' }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5rem',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            //px: '2rem',
            mb: '4rem',
          }}
        >
          <Box>
            <Logo />
          </Box>
          <Box sx={{ display: 'flex', gap: '2rem' }}>
            {footerMenu.map((block, i) => (
              <Box key={i} sx={{ width: '8rem' }}>
                <Typography
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 600,
                    color: '#FFFFFF',
                    fontFamily: '"Nunito Sans", sans-serif',
                    pb: '1rem',
                  }}
                >
                  {block.title}
                </Typography>
                <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                  {block.items.map((item, j) => (
                    <Box key={j} component="li" sx={{ py: '0.5rem' }}>
                      <Link
                        href={item.link}
                        sx={{
                          textDecoration: 'none',
                          color: grey[500],
                          ...styles.link,
                        }}
                      >
                        {item.text}
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
          {/* <Box sx={{ maxWidth: '29.125rem', width: '100%' }}>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 600,
                fontFamily: '"Nunito Sans", sans-serif',
              }}
            >
              Subscribe
            </Typography>
            <Typography
              component="p"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                mt: '1rem',
                fontFamily: '"Nunito Sans", sans-serif',
              }}
            >
              Join our mailing list for the latest updates.
            </Typography>
            <Box sx={{ mt: '1.5rem', display: 'flex' }}>
              <Paper
                component="form"
                sx={{
                  p: '0.375rem 0.75rem',
                  mr: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  width: '20.75rem',
                  borderRadius: '1.5rem',
                }}
              >
                <Box sx={{ paddingTop: '0.375rem' }} color={grey[500]}>
                  <MailIcon />
                </Box>
                <InputBase
                  sx={{
                    height: '2.25rem',
                    ml: '0.5rem',
                    flex: 1,
                    fontFamily: '"Nunito Sans", sans-serif',
                  }}
                  placeholder="Enter your email"
                  type="mail"
                />
              </Paper>
              <Button
                color="secondary"
                variant="outlined"
                sx={{ borderRadius: '1.5rem', fontFamily: '"Nunito Sans", sans-serif' }}
              >
                Subscribe
              </Button>
            </Box>
            <Typography
              component="p"
              sx={{
                fontSize: '0.75rem',
                fontWeight: 300,
                mt: '1rem',
                fontFamily: '"Nunito Sans", sans-serif',
              }}
            >
              By subscribing, you agree to our Privacy Policy and consent to receive updates from
              us.
            </Typography>
          </Box> */}
          <Box sx={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
            <Box
              sx={{
                display: 'flex',
                gap: '1.5rem',
                flexDirection: 'column',
                order: isXs ? 1 : 2,
              }}
            >
              <Typography
                sx={{ ...styles.link, textDecoration: 'underline' }}
                onClick={() => setModal('PRIVACY')}
              >
                Privacy Policy
              </Typography>
              <Typography
                sx={{ ...styles.link, textDecoration: 'underline' }}
                onClick={() => setModal('TERMS')}
              >
                Terms of Service
              </Typography>
              <Typography
                sx={{ ...styles.link, textDecoration: 'underline' }}
                onClick={() => setModal('COOKIE')}
              >
                Cookies Settings
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '0.75rem',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  py: isXs ? '3rem' : 0,
                }}
              >
                {socialLinks.map((social, index) => (
                  <Link
                    key={index}
                    href={social.link}
                    sx={{ '&:hover': { color: '#ffffff' }, display: 'inline-flex' }}
                    target="_blank"
                  >
                    {social.icon}
                  </Link>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ opacity: '.2' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: '1rem',
            flexWrap: 'wrap',
            gap: '1rem',
            flexDirection: isXs ? 'column' : 'row',
          }}
        >
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 400,
              fontFamily: '"Nunito Sans", sans-serif',
              order: isXs ? 3 : 0,
            }}
          >
            ShopiView © 2024. All rights reserved.
          </Typography>
          {/* <Box sx={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
            <Box
              sx={{
                display: 'flex',
                gap: '1.5rem',
                flexDirection: isXs ? 'column' : 'row',
                order: isXs ? 1 : 2,
              }}
            >
              <Typography
                sx={{ ...styles.link, textDecoration: 'underline' }}
                onClick={() => setModal('PRIVACY')}
              >
                Privacy Policy
              </Typography>
              <Typography
                sx={{ ...styles.link, textDecoration: 'underline' }}
                onClick={() => setModal('TERMS')}
              >
                Terms of Service
              </Typography>
              <Typography
                sx={{ ...styles.link, textDecoration: 'underline' }}
                onClick={() => setModal('COOKIE')}
              >
                Cookies Settings
              </Typography>
            </Box>
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              alignItems: isXs ? 'left' : 'center',
              gap: '0.75rem',
              order: isXs ? 2 : 3,
              flexDirection: isXs ? 'column' : 'row',
            }}
          >
            {/* <Box
              sx={{ display: 'flex', gap: '0.75rem', alignItems: 'center', py: isXs ? '3rem' : 0 }}
            >
              {socialLinks.map((social, index) => (
                <Link
                  key={index}
                  href={social.link}
                  sx={{ '&:hover': { color: '#ffffff' }, display: 'inline-flex' }}
                  target='_blank'
                >
                  {social.icon}
                </Link>
              ))}
            </Box> */}
            <ExternalLink
              href="https://www.freepik.com/"
              sx={{ color: '#fff', textDecoration: 'none', fontSize: '0.875rem' }}
            >
              Designed by FREEPIK
            </ExternalLink>
          </Box>
        </Box>
      </Container>

      {modal === 'COOKIE' && <CookiePolicyModal onClose={() => setModal('')} />}
      {modal === 'TERMS' && <TermsAndConditionsModal onClose={() => setModal('')} />}
      {modal === 'PRIVACY' && <PrivacyPolicyModal onClose={() => setModal('')} />}
    </Box>
  )
}

export default PublicFooter
