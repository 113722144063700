import carouselItem6 from 'assets/jpg/carousel-landing-images/1-frame-main-landing.jpg'
import carouselItem1 from 'assets/jpg/carousel-landing-images/1-frame-seller-landing.jpg'
import carouselItem7 from 'assets/jpg/carousel-landing-images/2-frame-main-landing.jpg'
import carouselItem2 from 'assets/jpg/carousel-landing-images/2-frame-seller-landing.jpg'
import carouselItem8 from 'assets/jpg/carousel-landing-images/3-frame-main-landing.jpg'
import carouselItem3 from 'assets/jpg/carousel-landing-images/3-frame-seller-landing.jpg'
import carouselItem9 from 'assets/jpg/carousel-landing-images/4-frame-main-landing.jpg'
import carouselItem4 from 'assets/jpg/carousel-landing-images/4-frame-seller-landing.jpg'
import carouselItem10 from 'assets/jpg/carousel-landing-images/5-frame-main-landing.jpg'
import carouselItem5 from 'assets/jpg/carousel-landing-images/5-frame-seller-landing.jpg'
import { ReactComponent as HIWReviewer1Icon } from 'assets/svg/HowItWorks/reviewer-1.svg'
import { ReactComponent as HIWReviewer2Icon } from 'assets/svg/HowItWorks/reviewer-2.svg'
import { ReactComponent as HIWReviewer3Icon } from 'assets/svg/HowItWorks/reviewer-3.svg'
import { ReactComponent as HIWReviewer4Icon } from 'assets/svg/HowItWorks/reviewer-4.svg'
import { ReactComponent as HIWReviewer5Icon } from 'assets/svg/HowItWorks/reviewer-5.svg'
import { ReactComponent as HIWReviewer6Icon } from 'assets/svg/HowItWorks/reviewer-6.svg'
import { ReactComponent as HIWReviewer7Icon } from 'assets/svg/HowItWorks/reviewer-7.svg'
import { ReactComponent as HIWReviewer8Icon } from 'assets/svg/HowItWorks/reviewer-8.svg'
import { ReactComponent as HIWSeller1Icon } from 'assets/svg/HowItWorks/seller-1.svg'
import { ReactComponent as HIWSeller2Icon } from 'assets/svg/HowItWorks/seller-2.svg'
import { ReactComponent as HIWSeller3Icon } from 'assets/svg/HowItWorks/seller-3.svg'
import { ReactComponent as HIWSeller4Icon } from 'assets/svg/HowItWorks/seller-4.svg'
import { ReactComponent as HIWSeller5Icon } from 'assets/svg/HowItWorks/seller-5.svg'
import { ReactComponent as HIWSeller6Icon } from 'assets/svg/HowItWorks/seller-6.svg'
import { ReactComponent as HIWSeller7Icon } from 'assets/svg/HowItWorks/seller-7.svg'
import { ReactComponent as HIWSeller8Icon } from 'assets/svg/HowItWorks/seller-8.svg'
import { ReactComponent as HIWWelocome1Icon } from 'assets/svg/HowItWorks/welcome-1.svg'
import { ReactComponent as HIWWelocome2Icon } from 'assets/svg/HowItWorks/welcome-2.svg'
import { ReactComponent as HIWWelocome3Icon } from 'assets/svg/HowItWorks/welcome-3.svg'
import { ReactComponent as HIWWelocome4Icon } from 'assets/svg/HowItWorks/welcome-4.svg'
import { ReactComponent as HIWWelocome5Icon } from 'assets/svg/HowItWorks/welcome-5.svg'
import { ReactComponent as HIWWelocome6Icon } from 'assets/svg/HowItWorks/welcome-6.svg'
import { ReactComponent as BenefitReviewerFirstIcon } from 'assets/svg/landing/benefitReviewerFirstIcon.svg'
import { ReactComponent as BenefitReviewerSecondIcon } from 'assets/svg/landing/benefitReviewerSecondIcon.svg'
import { ReactComponent as BenefitReviewerThirdIcon } from 'assets/svg/landing/benefitReviewerThirdIcon.svg'
import { ReactComponent as BenefitSellerFirstIcon } from 'assets/svg/landing/benefitSellerFirstIcon.svg'
import { ReactComponent as BenefitSellerSecondIcon } from 'assets/svg/landing/benefitSellerSecondIcon.svg'
import { ReactComponent as BenefitSellerThirdIcon } from 'assets/svg/landing/benefitSellerThirdIcon.svg'

export const landingText = {
  forWelcomePage: {
    title: 'Welcome to ShopiView Service',
    text: 'Discover the power of authentic feedback with ShopiView. Our service revolutionizes the way you gather and utilize customer reviews. Here`s what sets us apart.',
    carouselItems: [
      { url: carouselItem6, mobilePosition: '75% 20%' },
      { url: carouselItem7, mobilePosition: '65%' },
      { url: carouselItem8, mobilePosition: '50%' },
      { url: carouselItem9, mobilePosition: '65%' },
      { url: carouselItem10, mobilePosition: '25%' },
    ],
  },
  forSellerPage: {
    title: 'Enhance Your Product Page with ShopiView',
    text: 'Are you ready to take your product page to the next level? Install our ShopiView plugin and unlock a world of benefits',
    buttonText: 'Choose your plan',
    carouselItems: [
      { url: carouselItem1, mobilePosition: '85%' },
      { url: carouselItem2, mobilePosition: '75%' },
      { url: carouselItem3, mobilePosition: '55%' },
      { url: carouselItem4, mobilePosition: '75%' },
      { url: carouselItem5, mobilePosition: '65%' },
    ],
  },
  forReviewerPage: {
    title: 'Leave Your Mark with ShopiView',
    text: 'Are you ready to share your thoughts and earn rewards?',
    carouselItems: [
      { url: carouselItem6, mobilePosition: '75%' },
      { url: carouselItem7, mobilePosition: '65%' },
      { url: carouselItem8, mobilePosition: 'center' },
      { url: carouselItem9, mobilePosition: '65%' },
      { url: carouselItem10, mobilePosition: '25%' },
    ],
  },
}

export const sellerLandingFaqText = [
  {
    summary: 'What is ShopiView?',
    details:
      'ShopiView is a revolutionary platform that empowers sellers to gather authentic video reviews from their customers effortlessly. By leveraging the power of video feedback, businesses can enhance their credibility, increase sales, and foster stronger connections with their audience.',
  },
  {
    summary: 'How can ShopiView help my business?',
    details:
      'ShopiView allows you to collect authentic video feedback from your customers effortlessly. This helps boost your credibility, increase sales, and build stronger connections with your audience.',
  },
  {
    summary: 'What are the key features of ShopiView?',
    details:
      'ShopiView offers features like easy video review requests, a comprehensive analytics dashboard, the ability to engage with reviewers, and a reward program for incentivizing feedback.',
  },
  {
    summary: 'What distinguishes ShopiView from other review platforms?',
    details:
      'ShopiView stands out for its comprehensive approach to gathering and utilizing video reviews. Unlike traditional text-based reviews, video feedback offers a more engaging and authentic way for customers to share their experiences. Moreover, ShopiView provides sellers with advanced analytics, customizable reward options, and seamless synchronization with their Shopify stores.',
  },
  {
    summary: 'Can I monitor and respond to video reviews submitted by customers?',
    details:
      'Absolutely! ShopiView provides tools for monitoring review submissions and engaging with reviewers. You can respond to feedback directly through the platform to address any concerns or thank customers for their input.',
  },
]
export const reviewerLandingFaqText = [
  {
    summary: 'What is ShopiView?',
    details:
      'ShopiView is a platform that empowers reviewers like you to share authentic feedback through video reviews. By providing your honest opinions on products, you help shoppers make informed decisions while earning rewards for your valuable contributions.',
  },
  {
    summary: 'What are the benefits of using ShopiView?',
    details: `As a reviewer on ShopiView, you have the opportunity to influence purchasing decisions, contribute to product improvement, and earn rewards for your feedback. Additionally, your video reviews enhance transparency and credibility in the online shopping community.`,
  },
  {
    summary: 'How do I earn rewards for leaving video reviews?',
    details:
      'You earn rewards by sharing your honest feedback on products through video reviews. Each approved video feedback qualifies you for rewards that can be converted into discounts for future purchases at participating stores.',
  },
  {
    summary: 'How does ShopiView work?',
    details: `It's simple! After making a purchase from one of our partnered shops, you'll receive an invitation to leave a video review. Follow the link provided, record your feedback, and submit it through our platform. Once approved, you'll receive rewards that can be redeemed for discounts at participating shops.`,
  },
  {
    summary: 'Are there any incentives for leaving video reviews?',
    details:
      'Yes, absolutely! We offer various rewards for every approved video review you submit. These rewards can be collected and exchanged for discount coupons, allowing you to save on future purchases.',
  },
]

export const landingKeyFeatures = [
  {
    text: 'User-Friendly Interface: Our platform offers a seamless and intuitive experience for submitting video feedback',
  },
  {
    text: 'Transparent Reward System: Easily track your rewards and redeem them for discounts',
  },
  {
    text: 'Seamless Submission Process: Submit your video feedback with just a few clicks, no complicated steps involved',
  },
]

export const landingSellerBenefits = [
  {
    icon: <BenefitSellerFirstIcon />,
    text: 'Boost Sales with Authentic Reviews by showcasing customer experiences',
  },
  {
    icon: <BenefitSellerSecondIcon />,
    text: 'Increase trust and credibility with authentic video reviews',
  },
  {
    icon: <BenefitSellerThirdIcon />,
    text: 'Improve Engagement: Video content is more engaging and memorable',
  },
]

export const landingReviewerBenefits = [
  {
    icon: <BenefitReviewerFirstIcon />,
    text: 'Earn Rewards: Get paid for each approved video review you submit',
  },
  {
    icon: <BenefitReviewerSecondIcon />,
    text: 'Influence other shoppers with your authentic reviews',
  },
  {
    icon: <BenefitReviewerThirdIcon />,
    text: 'Help businesses improve their products and services with valuable insights',
  },
]

export const howItWorksWelcomePage = [
  {
    icon: <HIWWelocome1Icon />,
    title: 'Sign up for an account',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWWelocome2Icon />,
    title: 'Integrate your Shopify store',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWWelocome3Icon />,
    title: 'Request video reviews',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWWelocome4Icon />,
    title: 'Monitor review submissions',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWWelocome5Icon />,
    title: 'Engage with reviewers',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWWelocome6Icon />,
    title: 'Reward reviewers',
    textPosition: 'bottom',
    textAlign: 'center',
  },
] as const

export const howItWorksSellerPage = [
  {
    icon: <HIWSeller1Icon />,
    title: 'Sign up. Create your account to get started',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWSeller2Icon />,
    title: 'Integrate our ShopiView plugin into your product page',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWSeller3Icon />,
    title: `Add your Shopify store's information to sync your product listings`,
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWSeller4Icon />,
    title: 'Choose a subscription plan that suits your needs and budget',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWSeller5Icon />,
    title: 'Set up Email Notifications to engage with customers',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWSeller6Icon />,
    title: 'Review and moderate the video feedback',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWSeller7Icon />,
    title: 'Approve the video feedback and it will published to your Shopify store',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWSeller8Icon />,
    title: 'Reward reviewers with money, which they can use for future discounts at your store',
    textPosition: 'bottom',
    textAlign: 'center',
  },
] as const

export const howItWorksReviewerPage = [
  {
    icon: <HIWReviewer1Icon />,
    title: 'Purchase a product from Shopify stores',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWReviewer2Icon />,
    title: 'Receive an email inviting you to leave a video review',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWReviewer3Icon />,
    title: 'Click on the provided link to access the ShopiView platform',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWReviewer4Icon />,
    title: 'Create an account to get started',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWReviewer5Icon />,
    title: `Record a video review of products you've purchased`,
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWReviewer6Icon />,
    title: 'Receive rewards for each approved video feedback.',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWReviewer7Icon />,
    title: 'Convert your rewards into discounts for future purchases',
    textPosition: 'bottom',
    textAlign: 'center',
  },
  {
    icon: <HIWReviewer8Icon />,
    title: 'Reward reviewers with money, which they can use for future discounts at your store',
    textPosition: 'bottom',
    textAlign: 'center',
  },
] as const
