import { FC } from 'react'

import { Typography, TypographyProps } from '@mui/material'

interface Props extends TypographyProps {
  text?: string
}

const LandingTitle: FC<Props> = ({ text, sx, ...rest }) => {
  return (
    <Typography
      sx={{
        fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem', xl: '3rem' },
        fontWeight: 800,
        textAlign: 'center',
        ...sx,
      }}
      {...rest}
    >
      {text}
    </Typography>
  )
}

export default LandingTitle
