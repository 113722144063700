import { useNavigate } from 'react-router-dom'

import { Box, Typography, Container } from '@mui/material'

import {
  landingSellerBenefits,
  landingText,
  sellerLandingFaqText,
  howItWorksSellerPage,
} from 'constants/guest-layout'

import scrollToComponent from 'utils/scrollToComponent'

import Accordion from 'components/common/Accordion'
import Button from 'components/common/Button'
// import Button from 'components/common/Button'
import ColorBox from 'components/common/ColorBox'
import LandingTitle from 'components/common/LandingTitle'
import VideoPlayer from 'components/common/VideoPlayer'
import { HowItWorks } from 'components/public-layout/HowItWorks'
import LandingCarousel from 'components/public-layout/LandingCarousel'
import SellerPlansPricing from 'components/public-layout/SellerPlansPricing'
import WaitListForm from 'components/public-layout/WaitListForm'

const SellerLanding = () => {
  return (
    <>
      <LandingCarousel
        title={landingText.forSellerPage.title}
        text={landingText.forSellerPage.text}
        //buttonText={landingText.forSellerPage.buttonText}
        carouselItems={landingText.forSellerPage.carouselItems}
      />

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4.68rem',
          alignItems: 'center',
          margin: '0 auto',
        }}
      >
        <Box
          id="benefits"
          sx={{
            width: '100%',
            py: '4.68rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            alignItems: 'center',
          }}
        >
          <Box>
            <LandingTitle text="Benefits" />
            <Typography
              sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                lineHeight: 1.5,
                textAlign: 'center',
                mt: '0.5rem',
              }}
            >
              Join our community of sellers and enjoy the following benefits
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '4rem 1.5rem',
              flexWrap: 'wrap',
              mt: '1.5rem',
              width: '100%',
            }}
          >
            {landingSellerBenefits.map((benefit) => (
              <ColorBox
                sx={{
                  width: '100%',
                  p: '4rem 1.25rem',
                  position: 'relative',
                  bgcolor: '#000000',
                  color: '#FFFFFF',
                  minWidth: '21.5rem',
                  fontSize: { xs: '1.25rem', md: '1.5rem' },
                }}
                key={benefit.text}
              >
                <Box sx={{ position: 'absolute', top: '-3.125rem', left: '1rem' }}>
                  {benefit.icon}
                </Box>
                {benefit.text}
              </ColorBox>
            ))}
          </Box>
        </Box>
      </Container>

      <Box sx={{ bgcolor: '#F7F5F7', width: '100%', pb: '4.68rem' }}>
        <Container maxWidth="lg">
          <Box
            id="howItWorks"
            sx={{
              width: '100%',
              pt: '4.68rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '3rem',
              alignItems: 'center',
            }}
          >
            <LandingTitle text="How It Works?" />
            {/*@ts-ignore*/}
            <HowItWorks items={howItWorksSellerPage} direction="column" textMaxWidth={462} />
            <Box sx={{ pt: '0.5rem' }}>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '2rem',
                  fontWeight: 700,
                  textAlign: 'center',
                  mb: '3rem',
                }}
              >
                Watch Our Video Review
              </Typography>
              <VideoPlayer
                sx={{
                  borderRadius: '1.25rem 1.25rem 1.25rem 1.25rem',
                  overflow: 'hidden',
                  aspectRatio: '16/10.41',
                }}
                src="https://minio-hq-ny-s3.eastrelay.com/siv-dev/landings/VideoReview%20-%20How%20to%20install%20Plugin%282%29.mp4"
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          backgroundColor: '#262626',
          p: '4.68rem 4rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1.5rem',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '1.25rem', md: '2rem' },
            fontWeight: 700,
            color: '#FFFFFF',
            textAlign: 'center',
          }}
        >
          Limited Time Offer:
          <br />
          Join waitlist Now and Enjoy Our Exclusive Offer!
        </Typography>
        <Typography
          sx={{ fontSize: '1rem', fontWeight: 600, color: '#FFFFFF', textAlign: 'center' }}
        >
          Take advantage of our limited-time offer and join ShopiView for free!
        </Typography>
        <Button
          onClick={(event) => scrollToComponent(event, 'wait_list')}
          sx={{
            width: { xs: '100%', md: '12.125rem' },
            minWidth: '12.125rem',
            fontSize: '1rem',
            fontWeight: '600',
            height: '3rem',
            borderRadius: '1.875rem',
            border: '1px solid #000000',
            color: '#262626',
            backgroundColor: '#CBFA92',
            '&:hover': {
              backgroundColor: '#B4F861',
            },
          }}
        >
          Join waitlist
        </Button>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',

          alignItems: 'center',
          margin: '0 auto',
          pb: '4.68rem',
        }}
      >
        <SellerPlansPricing />

        <Box
          id="faq"
          sx={{
            width: '100%',
            pt: '4.68rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
          }}
        >
          <LandingTitle text="FAQ" />
          <Box>
            {sellerLandingFaqText.map((faq) => (
              <Accordion key={faq.summary} summary={faq.summary} details={faq.details} />
            ))}
          </Box>
        </Box>

        {/* <ColorBox
          sx={{
            p: '3rem',
            flexDirection: 'column',
            bgcolor: '#CBFA92',
            width: '100%',
            textAlign: 'justify',
            alignItems: 'start',
          }}
        >
          <Typography
            sx={{ fontSize: { xs: '1.25rem', md: '2rem' }, fontWeight: '700', mb: '1rem' }}
          >
            Start Boosting Your Sales Today
          </Typography>
          <Typography
            sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, fontWeight: '500', mb: '1.5rem' }}
          >
            Getting started with ShopiView is simple. Just sign up for an account, integrate your
            Shopify store by installing our plugin, and start requesting video reviews from your
            customers.
          </Typography>
          <Button
            onClick={() => navigate('/seller-registration')}
            variant="outlined"
            sx={{ borderRadius: '1.5rem' }}
          >
            Start as a Seller
          </Button>
        </ColorBox> */}
      </Container>
      <Container maxWidth="lg" id="wait_list" sx={{ pb: '4.68rem' }}>
        <WaitListForm textColor="#262626" bgColor="#CBFA92"></WaitListForm>
      </Container>
    </>
  )
}

export default SellerLanding
