import { FC } from 'react'
import Countdown, { zeroPad } from 'react-countdown'

import { Box, Typography } from '@mui/material'

import { discountEndDay } from 'constants/discount-end-day'

const styles = {
  countNumber: {
    color: '#CBFA92',
    fontSize: { xs: '3rem', sm: '6rem', md: '6rem', lg: '6rem', xl: '6rem' },
    fontWeight: 900,
    textShadow: '-1px -1px 0 #262626, 1px -1px 0 #262626, -1px 1px 0 #262626, 1px 1px 0 #262626',
  },
  helperText: { fontSize: '1.25rem', fontWeight: 600 },
}

const renderer: FC<{
  days: number
  hours: number
  minutes: number
}> = ({ days, hours, minutes }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1.5rem' }}>
      <Box>
        <Typography component="span" sx={styles.countNumber}>
          {zeroPad(days)}
        </Typography>
        <Typography component="span" sx={styles.helperText}>
          days
        </Typography>
      </Box>
      <Box>
        <Typography component="span" sx={styles.countNumber}>
          {zeroPad(hours)}
        </Typography>
        <Typography component="span" sx={styles.helperText}>
          hours
        </Typography>
      </Box>
      <Box>
        <Typography component="span" sx={styles.countNumber}>
          {zeroPad(minutes)}
        </Typography>
        <Typography component="span" sx={styles.helperText}>
          mins
        </Typography>
      </Box>
    </Box>
  )
}

const DiscountCountdown: FC = () => {
  return (
    <Box sx={{ py: '2rem' }}>
      <Countdown date={discountEndDay} renderer={renderer} />
    </Box>
  )
}

export default DiscountCountdown
