import { useMediaQuery, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import { howItWorksWelcomePage, landingText } from 'constants/guest-layout'

import ColorBox from 'components/common/ColorBox'
import LandingTitle from 'components/common/LandingTitle'
import VideoPlayer from 'components/common/VideoPlayer'
import { HowItWorks } from 'components/public-layout/HowItWorks'
import LandingCarousel from 'components/public-layout/LandingCarousel'
import WaitListForm from 'components/public-layout/WaitListForm'

const Welcome = () => {
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <>
      <LandingCarousel
        title={landingText.forWelcomePage.title}
        text={landingText.forWelcomePage.text}
        carouselItems={landingText.forWelcomePage.carouselItems}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4.68rem',
          alignItems: 'center',
          pb: '4.68rem',
        }}
      >
        <Container maxWidth="lg">
          <Box
            id="features"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '3rem',
              alignItems: 'center',

              pt: '4.68rem',
            }}
          >
            <Box>
              <LandingTitle text="Key Features" />
              <Typography
                sx={{ fontSize: '1.125rem', fontWeight: 400, textAlign: 'center', mt: '2rem' }}
              >
                At <b>ShopiView</b>, we empower businesses to gather authentic video reviews from
                their customers effortlessly. By leveraging the power of video feedback, businesses
                can enhance their credibility, increase sales, and foster stronger connections with
                their audience.
              </Typography>
            </Box>
            <Box>
              <Box sx={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
                <ColorBox
                  sx={{
                    bgcolor: '#262626',
                    color: '#FFFFFF',
                    fontSize: { xs: '1.25rem', md: '1.5rem' },
                    minWidth: '20rem',
                  }}
                >
                  Easily request video reviews from customers
                </ColorBox>
                <ColorBox sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' }, minWidth: '20rem' }}>
                  Seamless integration with Shopify stores
                </ColorBox>
                <ColorBox sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' }, minWidth: '20rem' }}>
                  Comprehensive analytics dashboard
                </ColorBox>
                <ColorBox
                  sx={{
                    flex: '1 0 48%',
                    fontSize: { xs: '1.25rem', md: '1.5rem' },
                    minWidth: '20rem',
                  }}
                >
                  Engage with reviewers and respond to feedback
                </ColorBox>
                <ColorBox
                  sx={{
                    flex: '1 0 48%',
                    bgcolor: '#CBFA92',
                    fontSize: { xs: '1.25rem', md: '1.5rem' },
                    minWidth: '20rem',
                  }}
                >
                  Reward program for reviewers
                </ColorBox>
              </Box>
            </Box>
          </Box>
        </Container>

        <Box sx={{ bgcolor: '#F7F5F7', width: '100%', pb: '4.68rem' }}>
          <Container maxWidth="lg">
            <Box
              id="howItWorks"
              sx={{
                width: '100%',
                pt: '4.68rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem',
                alignItems: 'center',
              }}
            >
              <LandingTitle text="How It Works?" />
              {/* <StepsImage style={{maxWidth: 1152,  height: 'auto'}}/> */}

              <HowItWorks
                //@ts-ignore
                items={howItWorksWelcomePage}
                direction={isLg ? 'row' : 'column'}
                textMaxWidth={172}
              />
              <Box sx={{ pt: '2rem' }}>
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '2rem',
                    fontWeight: 700,
                    textAlign: 'center',
                    mb: '3rem',
                  }}
                >
                  Watch Our Video Review
                </Typography>
                <VideoPlayer
                  sx={{
                    borderRadius: '1.25rem 1.25rem 1.25rem 1.25rem',
                    overflow: 'hidden',
                    aspectRatio: '16/8.675',
                  }}
                  src="https://minio-hq-ny-s3.eastrelay.com/siv-dev/landings/Shopiview%20v1.4%20.mp4"
                />
              </Box>
            </Box>
          </Container>
        </Box>

        <Container maxWidth="lg" id="wait_list">
          <WaitListForm textColor="#FFFFFF" bgColor="#000000" />
        </Container>
      </Box>
    </>
  )
}

export default Welcome
