import axios, { AxiosError, AxiosResponse } from 'axios'
import { useLayoutEffect } from 'react'

import KeycloakService from 'services/KeycloakService'

export const baseURL = process.env.REACT_APP_API_URL
export const cleverURL = process.env.REACT_APP_CW_API_URL
export const axiosApi = axios.create({
  baseURL,
})

export const AxiosInterceptor = () => {
  useLayoutEffect(() => {
    const requestInterceptor = axiosApi.interceptors.request.use(
      async (config) => {
        try {
          const token = await KeycloakService.getTokenWithUpdate()
          config.headers.Authorization = `Bearer ${token}`
        } catch (e) {
          //not autorization
        }

        return config
      },
      (error) => {
        console.log('requestInterceptor error')
        return Promise.reject(error)
      }
    )

    const responseInterceptor = axiosApi.interceptors.response.use(
      (response: AxiosResponse) => {
        return response
      },
      (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          //KeycloakService.doLogout()
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axiosApi.interceptors.request.eject(requestInterceptor)
      axiosApi.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return null
}

export default AxiosInterceptor
