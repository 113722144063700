import { FC } from 'react'

import { Box, Typography } from '@mui/material'

import { ReactComponent as LogoSvg } from 'assets/svg/logo_min.svg'

interface Props {
  white?: boolean
  hideText?: boolean
}

const Logo: FC<Props> = ({ white = true, hideText = false }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <LogoSvg />
      {!hideText && (
        <Typography
          component="span"
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            color: white ? '#FFFFFF' : '#000000',
            fontSize: '1rem',
            fontWeight: white ? '500' : '600',
            fontOpticalSizing: 'auto',
          }}
        >
          ShopiView
        </Typography>
      )}
    </Box>
  )
}

export default Logo
