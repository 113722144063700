import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'

import { Box, Typography } from '@mui/material'

import { useWaitListMutation } from 'services/public'

import Button from 'components/common/Button'
import Checkbox from 'components/common/Checkbox'
import ColorBox from 'components/common/ColorBox'
import Modal from 'components/common/Modal'
import TextInput from 'components/common/TextInput'
import { PrivacyPolicyModal } from 'components/policies/PrivacyPolicyModal'
import { TermsAndConditionsModal } from 'components/policies/TermsAndConditionsModal'

import { waitListSchema } from './validation'

interface Props {
  textColor: string
  bgColor: string
}

type FormType = {
  name: string
  email: string
  phone?: string | null
  terms?: boolean
}

const WaitListForm: FC<Props> = ({ textColor, bgColor }) => {
  const [addToWaitList, { isLoading }] = useWaitListMutation()
  const [modal, setModal] = useState<'TERMS' | 'PRIVACY' | ''>('')
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false)
  const [isShowCaptchaModal, setIsShowCaptchaModal] = useState(false)
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormType>({
    mode: 'onChange',
    resolver: yupResolver(waitListSchema),
    defaultValues: {
      name: '',
      email: '',
      phone: null,
      terms: false,
    },
  })

  const onSubmit = async ({ terms: _, ...rest}: FormType) => {
    return await addToWaitList(rest)
      .unwrap()
      .then(() => {
        setIsShowSuccessModal(true)
        reset()
      })
      .catch(() => {})
  }

  return (
    <>
      <ColorBox
        sx={{
          p: { xs: '1rem', sm: '2rem', md: '3rem' },
          flexDirection: 'column',
          color: textColor,
          bgcolor: bgColor,
          width: '100%',
          fontSize: { xs: '1.125rem', md: '1.5rem' },
          alignItems: 'start',
          minHeight: 'auto',
        }}
      >
        <Box width="100%" textAlign="center">
          Join our waitlist and get a free six-month subscription! Fill out the feedback form to
          join the waitlist, and we will notify you when the product launches
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            //flexDirection: 'column',
            gap: '1rem',
            mt: '2rem',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                //flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <TextInput
                name="name"
                control={control}
                errors={errors}
                placeholder="Name"
                hiddenLabel
                fullWidth={true}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: '#FFFFFF',
                    borderRadius: '1.875rem',
                    height: '3rem',
                  },
                  border: 'none',
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
              <TextInput
                name="email"
                control={control}
                errors={errors}
                placeholder="Email"
                fullWidth={true}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: '#FFFFFF',
                    borderRadius: '1.875rem',
                    height: '3rem',
                  },
                  border: 'none',
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
              <TextInput
                name="phone"
                control={control}
                errors={errors}
                placeholder="Phone"
                fullWidth={true}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: '#FFFFFF',
                    borderRadius: '1.875rem',
                    height: '3rem',
                  },
                  border: 'none',
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            </Box>
            <Checkbox
              name="terms"
              control={control}
              errors={errors}
              label={
                <Box sx={{ display: 'inline-flex', gap: '0.2rem' }}>
                  I accept the
                  <Typography
                    sx={{
                      color: '#1771CC',
                      fontWeight: 400,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setModal('TERMS')
                    }}
                  >
                    Terms
                  </Typography>
                  <Typography>and</Typography>
                  <Typography
                    sx={{
                      color: '#1771CC',
                      fontWeight: 400,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setModal('PRIVACY')
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Box>
              }
            />
          </Box>
          <Button
            onClick={ async() => {
              const isFormValid = await trigger(['name', 'email', 'phone', 'terms'])
              isFormValid && setIsShowCaptchaModal(true)
            }}
            disabled={isLoading}
            sx={{
              width: { xs: '100%', md: '12.125rem' },
              minWidth: '12.125rem',
              fontSize: '1rem',
              fontWeight: '600',
              height: '3rem',
              borderRadius: '1.875rem',
              border: '1px solid #000000',
              color: '#262626',
              backgroundColor: '#CBFA92',
              '&:hover': {
                backgroundColor: '#B4F861',
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </ColorBox>
      {modal === 'PRIVACY' && <PrivacyPolicyModal onClose={() => setModal('')} />}
      {modal === 'TERMS' && <TermsAndConditionsModal onClose={() => setModal('')} />}
      {isShowCaptchaModal && (
        <Modal title={' '} open onClose={() => setIsShowCaptchaModal(false)}>
          <Modal.Content sx={{ alignItems: 'center', height: '10rem' }}>
            <ReCAPTCHA
              sitekey="6LcscxsqAAAAAKQ73N8ZwkU1zWIW5srmJOz-SUt7"
              onChange={async (v) => {
                if (v) {
                  await handleSubmit(onSubmit)()
                  setIsShowCaptchaModal(false)
                }
              }}
            />
          </Modal.Content>
        </Modal>
      )}
      {isShowSuccessModal && (
        <Modal
          open={true}
          showCloseButton={false}
          onClose={() => {
            setIsShowSuccessModal(false)
          }}
          sx={{ borderRadius: '1.25rem', padding: '1.5rem', maxWidth: '25.75rem', gap: '1.5rem' }}
        >
          <Modal.Content sx={{ p: 0 }}>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 600 }}>Message sent!</Typography>
            <Typography sx={{ fontSize: '1rem', fontWeight: 500 }}>
              Thank you for joining our waitlist. <br />
              We will contact you within one business day.
            </Typography>
          </Modal.Content>
          <Modal.Actions sx={{ justifyContent: 'start', p: 0 }}>
            <Button
              onClick={() => {
                setIsShowSuccessModal(false)
              }}
              sx={{ px: '3rem', borderRadius: '1.85rem' }}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default WaitListForm
