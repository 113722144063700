import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery, Theme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'

import { NavMenuItemType } from 'types/NavMenuItem'

import scrollToComponent from 'utils/scrollToComponent'

import KeycloakService from 'services/KeycloakService'

import Button from 'components/common/Button'
import Logo from 'components/common/Logo'
import NavMenuItem from 'components/public-layout/PublicAppBar/NavMenuItem'

import { MobileAppBar } from '../MobileAppBar'

const Y_THRESHOLD = 200

const PublicAppBar: FC = () => {
  const location = useLocation()
  const [activeSection, setActiveSection] = useState<string>('header')
  const [appBarPositionTop, setAppBarPositionTop] = useState<boolean>(true)
  const isHeaderAsAppBar = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const handleDrawerOpen = (state: boolean) => {
    setIsDrawerOpen(state)
  }

  const isLanding =
    location.pathname === '/welcome' ||
    location.pathname === '/sellers' ||
    location.pathname === '/reviewers'

  const menuItems: NavMenuItemType[] = location.pathname.includes('/invite')
    ? [
        { text: 'Features', link: `#`, scrollToComponentWithId: 'features' },
        { text: 'How it works?', link: `#`, scrollToComponentWithId: 'howItWorks' },
        { text: 'For Sellers', link: `/sellers` },
        { text: 'For Reviewers', link: `/reviewers` },
      ]
    : location.pathname === '/welcome'
      ? [
          { text: 'Features', link: `#`, scrollToComponentWithId: 'features' },
          { text: 'How it works?', link: `#`, scrollToComponentWithId: 'howItWorks' },
          { text: 'For Sellers', link: `/sellers` },
          { text: 'For Reviewers', link: `/reviewers` },
        ]
      : location.pathname === '/sellers'
        ? [
            { text: 'For Sellers', link: `/sellers`, scrollToComponentWithId: 'header' },
            { text: 'Benefits', link: `#`, scrollToComponentWithId: 'benefits' },
            {
              text: 'How it works?',
              link: `#`,
              scrollToComponentWithId: 'howItWorks',
            },
            { text: 'Pricing', link: `#`, scrollToComponentWithId: 'pricing' },
            { text: 'FAQ', link: `#`, scrollToComponentWithId: 'faq' },
          ]
        : [
            { text: 'For Reviewers', link: `/reviewers`, scrollToComponentWithId: 'header' },
            { text: 'Benefits', link: `#`, scrollToComponentWithId: 'benefits' },
            { text: 'How it works?', link: `#`, scrollToComponentWithId: 'howItWorks' },
            { text: 'Features', link: `#`, scrollToComponentWithId: 'features' },
            { text: 'FAQ', link: `#`, scrollToComponentWithId: 'faq' },
          ]

  const appBarPositionStyle = () => {
    setAppBarPositionTop(window.scrollY === 0)
  }

  const activeNavMenu = () => {
    const scrollY = window.scrollY
    const menuSections = menuItems.filter((item) => item.scrollToComponentWithId)
    const listSectionsIds = menuSections.map((item) => `#${item.scrollToComponentWithId}`)
    const sections = document.querySelectorAll<HTMLElement>(listSectionsIds.join())
    sections.forEach((current) => {
      const sectionTop = current.offsetTop - window.innerHeight / 2
      const sectionHeight = current.offsetHeight
      const sectionId = current.getAttribute('id')
      if (scrollY < Y_THRESHOLD) {
        setActiveSection('header')
      } else if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        setActiveSection(sectionId!)
      }
    })
  }

  const listenScrollEvent = () => {
    appBarPositionStyle()
    activeNavMenu()
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
    return () => window.removeEventListener('scroll', listenScrollEvent)
  }, [menuItems])

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: appBarPositionTop ? 'transparent' : '#FFFFFF',
        color: '#CAD3C8',
        display: 'flex',
        justifyContent: 'center',
        transition: 'background-color 0.2s ease-in',
      }}
    >
      {isHeaderAsAppBar && (
        <Container maxWidth="lg" disableGutters>
          <Toolbar sx={{ py: '1.25rem', maxWidth: 'lg', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link href="/welcome" sx={{ textDecoration: 'none', lineHeight: 1 }}>
                <Logo white={appBarPositionTop && isLanding} />
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1.5rem',
              }}
            >
              {!location.pathname.includes('registration') &&
                menuItems.map((item) => (
                  <NavMenuItem
                    key={item.text}
                    item={item}
                    current={activeSection}
                    color={appBarPositionTop ? '#FFFFFF' : '#262626'}
                  />
                ))}
            </Box>
            {location.pathname.includes('welcome') || location.pathname.includes('sellers') ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  gap: '1rem',
                }}
              >
                <Button
                  onClick={() => {
                    window.location.href = 'https://calendly.com/shopiview_waitlist/30min'
                  }}
                  endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
                  sx={{
                    bgcolor: '#FFFFFF',
                    color: '#262626',
                    borderRadius: '1.5rem',
                    '&:hover': { bgcolor: '#DADADA' },
                  }}
                >
                  Schedule call
                </Button>
                <Button
                  href=""
                  onClick={(event) => {
                    scrollToComponent(event, 'wait_list')
                  }}
                  sx={{ borderRadius: '1.5rem' }}
                  endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
                >
                  Join waitlist
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  gap: '1rem',
                }}
              >
                {/* <Button
                  onClick={() => KeycloakService.doLogin()}
                  endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
                  sx={{
                    bgcolor: '#FFFFFF',
                    color: '#262626',
                    borderRadius: '1.5rem',
                    '&:hover': { bgcolor: '#DADADA' },
                  }}
                >
                  Sign In
                </Button> */}
                {/* <Button
                href="/seller-registration"
                sx={{ borderRadius: '1.5rem' }}
                endIcon={<ArrowForwardIosIcon sx={{ height: '0.75rem', width: '0.75rem' }} />}
              >
                Sign Up
              </Button> */}
              </Box>
            )}
          </Toolbar>
        </Container>
      )}
      {!isHeaderAsAppBar && (
        <MobileAppBar
          activeSection={activeSection}
          isDrawerOpen={isDrawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          menuItems={menuItems}
          appBarPositionTop={appBarPositionTop}
          isLanding={isLanding}
        />
      )}
    </AppBar>
  )
}

export default PublicAppBar
