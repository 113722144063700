import { Typography, Box } from '@mui/material'

import Modal from 'components/common/Modal'

interface Props {
  onClose: () => void
}

const GOOGLE_COOKIE_SETTINGS = 'https://support.google.com/chrome/answer/95647?hl=en'
const SAFARI_COOKIE_SETTINGS = 'https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac'
const MOZILLA_COOKIE_SETTINGS =
  'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US'
const EDGE_COOKIE_SETTINGS =
  'https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d'

export const POLICIES_TITLE_STYLES = { fontWeight: 600, fontSize: '0.875rem', mb: '0.5rem' }

export const CookiePolicyModal = ({ onClose }: Props) => {
  return (
    <Modal
      open
      onClose={onClose}
      title="Shopiview Cookie Policy"
      titleAlign="left"
      titleStyles={{
        p: '1.5rem',
        fontWeight: 700,
      }}
      sx={{ maxWidth: '74.125rem', gap: 0 }}
    >
      <Modal.Content
        sx={{
          p: '0 1.5rem 1.5rem',
        }}
      >
        <Typography component="p" sx={{ fontSize: '0.75rem' }}>
          Last Updated: June 1, 2024
        </Typography>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            1. Introduction
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            This Cookie Policy explains how Shopiview, operated and owned by We Can Develop IT LLC
            (&quot;We&quot;, &quot;Us&quot;, &quot;Our&quot;), uses cookies and similar tracking
            technologies on our platform (&quot;Platform&quot;). By using the Platform, you agree to
            the use of cookies as outlined in this policy.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            2. What Are Cookies?
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Cookies are small text files that are placed on your device when you visit a website.
            They are widely used to make websites work more efficiently and to provide information
            to the website owners.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            3. Types of Cookies We Use
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.1. Essential Cookies: These cookies are necessary for the Platform to function
            properly. They enable you to navigate the site and use its features, such as accessing
            secure areas.
          </Typography>

          <Typography component="p" sx={{ ml: '1rem' }}>
            3.2. Performance Cookies: These cookies collect information about how visitors use the
            Platform, such as which pages are visited most often. This helps us improve the
            Platform&apos;s functionality.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.3. Functionality Cookies: These cookies allow the Platform to remember choices you
            make (such as your username and language preferences) and provide enhanced, more
            personalized features.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.4. Targeting/Advertising Cookies: These cookies are used to deliver advertisements
            more relevant to you and your interests. They are also used to limit the number of times
            you see an ad and to measure the effectiveness of advertising campaigns.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            4. How We Use Cookies
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.1. To Improve User Experience: We use cookies to understand how you interact with the
            Platform, which helps us improve its functionality and performance.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.2. To Personalize Content: Cookies allow us to provide you with a more personalized
            experience by remembering your preferences and settings.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.3. To Analyze Usage: We use cookies to gather statistical information about the usage
            of the Platform, which helps us understand how users engage with our content and
            services.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.4. To Provide Targeted Advertising: Cookies help us deliver relevant advertisements to
            you based on your interests and online activities.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            5. Managing Cookies
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            You can manage or delete cookies using your browser settings. Here&apos;s how you can do
            it in some popular browsers:
          </Typography>
          <ul style={{ margin: 0 }}>
            <li>
              <Typography component="p">
                Google Chrome:{' '}
                <a href={GOOGLE_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                  Chrome Cookie Settings
                </a>
              </Typography>{' '}
            </li>
            <li>
              <Typography component="p">
                Mozilla Firefox:{' '}
                <a href={MOZILLA_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                  Firefox Cookie Settings
                </a>
              </Typography>
            </li>
            <li>
              <Typography component="p">
                Safari:{' '}
                <a href={SAFARI_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                  Safari Cookie Settings
                </a>
              </Typography>
            </li>
            <li>
              <Typography component="p">
                Microsoft Edge:{' '}
                <a href={EDGE_COOKIE_SETTINGS} target="_blank" rel="noreferrer">
                  Edge Cookie Settings
                </a>
              </Typography>
            </li>
          </ul>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Please note that if you choose to block cookies, you may not be able to access all or
            parts of our Platform or some features may not function properly.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            6. Third-Party Cookies
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            We may also use third-party cookies from service providers that assist us in various
            functions, including analytics, advertising, and improving user experience. These third
            parties may use cookies to collect information about your online activities over time
            and across different websites.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            7. Changes to This Cookie Policy
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            We may update this Cookie Policy from time to time. The updated Cookie Policy will be
            posted on the Platform with the effective date. Your continued use of the Platform after
            any changes indicates your acceptance of the new Cookie Policy.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            8. Contact Information
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            If you have any questions about this Cookie Policy, please contact us at:
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            We Can Develop IT LLC
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            12000 Ancient Crest Circle, Apt 12107
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Franklin, Tennessee, 37067
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Email: support@shopiview.com
          </Typography>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
