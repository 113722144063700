import { FC } from 'react'

import AddIcon from '@mui/icons-material/Add'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface Props {
  summary: string
  details: string
}

const expandIcon = (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      m: '1px auto',
      width: '3rem',
      height: '3rem',
      borderRadius: '50%',
      bgcolor: '#CBFA92',
    }}
  >
    <AddIcon sx={{ color: '#000000' }} />
  </Box>
)

const Accordion: FC<Props> = ({ summary, details }) => {
  return (
    <MuiAccordion
      disableGutters
      elevation={0}
      sx={{
        borderBottom: '1px #DFDFDF solid',
        py: '1rem',
        '&.MuiButtonBase-root-MuiAccordionSummary-root': { p: '0' },
      }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        sx={{
          p: '0',
          '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
            transform: 'rotate(45deg)',
          },
          '&.Mui-expanded .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiBox-root': {
            backgroundColor: '#FFFFFF00',
            border: '1px #000000 solid',
          },
        }}
      >
        <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', p: 0 }}>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: 'justify', px: '0' }}>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5rem',
            textAlign: 'left',
          }}
        >
          {details}
        </Typography>
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion
