import { FC } from 'react'

import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { landingPricesText } from 'constants/prices'

import Button from 'components/common/Button'
import LandingTitle from 'components/common/LandingTitle'

import DiscountCountdown from '../DiscountCountdown'
import scrollToComponent from 'utils/scrollToComponent'

const SellerPlansPricing: FC = () => {
  return (
    <Box
      id="pricing"
      sx={{ width: '100%', pt: '4.68rem', display: 'flex', flexDirection: 'column', gap: '3rem' }}
    >
      <LandingTitle text="Pricing" />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1.5rem', justifyContent: 'center' }}>
        {landingPricesText.map((item) => (
          <Box
            key={item.level}
            sx={{
              maxWidth: '23rem',
              width: '100%',
              bgcolor: '#F7F5F7',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              p: '2rem',
              borderRadius: '1.25rem',
            }}
          >
            <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', mb: '2rem' }}>
              {item.level}
            </Typography>
            <Box sx={{ position: 'relative', ml: item.sale ? '0.75rem' : 0 }}>
              <Box sx={{ display: 'flex', my: '1rem' }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    lineHeight: '80%',
                    display: 'flex',
                    alignItems: 'end',
                  }}
                >
                  $
                </Typography>
                <Typography sx={{ fontSize: '2rem', fontWeight: 700, lineHeight: '80%' }}>
                  {item.sum}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.75rem',
                    fontWeight: 700,
                    alignSelf: 'start',
                    lineHeight: '100%',
                  }}
                >
                  00
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    fontWeigh: 300,
                    alignSelf: 'end',
                    lineHeight: '100%',
                    color: grey[600],
                  }}
                >
                  /
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 300,
                    alignSelf: 'end',
                    color: grey[600],
                    ml: '0.375rem',
                  }}
                >
                  month
                </Typography>
              </Box>
              {item.sale && (
                <>
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '7.25rem',
                      height: '2px',
                      backgroundColor: '#EA1616',
                      borderRadius: '2px',
                      transform: 'rotate(-21deg)',
                      bottom: '1.875rem',
                      left: '-1.75rem',
                    }}
                  ></Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      bgcolor: '#EA1616',
                      borderRadius: '0.25rem',
                      padding: '0.25rem 0.5rem',
                      bottom: '2.5rem',
                      left: '5.625rem',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        lineHeight: '80%',
                        display: 'flex',
                        alignItems: 'end',
                        color: '#FFFFFF',
                      }}
                    >
                      $
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '2rem',
                        fontWeight: 700,
                        lineHeight: '80%',
                        color: '#FFFFFF',
                      }}
                    >
                      0
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: 700,
                        alignSelf: 'start',
                        lineHeight: '100%',
                        color: '#FFFFFF',
                      }}
                    >
                      00
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box sx={{ textAlign: 'left', flex: 1 }}>
              {item.text.map((text) => (
                <Box key={text.subtitle} sx={{ my: '1rem' }}>
                  <Typography fontWeight={700} fontSize="1.125rem">
                    {text.subtitle}
                  </Typography>
                  <Typography fontWeight={400} fontSize="1rem" lineHeight="1.07rem">
                    {text.details}
                  </Typography>
                </Box>
              ))}
            </Box>
            {/* <Button onClick={item.func} size="small" fullWidth sx={{ mt: '1rem' }}>
              Select
            </Button> */}
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
        <Typography
          sx={{ fontSize: { xs: '1.25rem', md: '2rem' }, fontWeight: 700, lineHeight: '2.1rem', textAlign: 'center' }}
        >
          Get access to ShopiView&apos;s premium features for free!
        </Typography>
        <Typography
          sx={{ fontSize: { xs: '1.25rem', md: '2rem' }, fontWeight: 700, lineHeight: '2.1rem', textAlign: 'center' }}
        >
          An exclusive Early Bird offer is available for a limited time only.
        </Typography>
        <Typography
          sx={{ fontSize: '0.875rem', fontWeight: 400, textAlign: 'center', mt: '-0.5rem' }}
        >
          A free unlimited plan is available if you record a video feedback of using our product.
        </Typography>
      </Box>
      <DiscountCountdown />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={(event) => scrollToComponent(event, 'wait_list')}
          sx={{
            width: '18.75rem',
            height: '4.375rem',
            borderRadius: '6.25rem',
            fontSize: '1.25rem',
            fontWeight: 600,
          }}
        >
          Join waitlist
        </Button>
      </Box>
    </Box>
  )
}

export default SellerPlansPricing
