import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { store } from 'store'
import { theme } from 'theme'

import './styles/index.scss'

import { ThemeProvider } from '@mui/material'

import KeycloakService from 'services/KeycloakService'
import googleAnalyticsService from 'services/googleAnalyticsService'

import reportWebVitals from './reportWebVitals'
import { router } from './routes'

//googleAnalyticsService.init()

const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const renderApp = () =>
  root.render(
    //<React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5} preventDuplicate>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
    //</React.StrictMode>
  )

//KeycloakService.initKeycloak(renderApp)
renderApp()
reportWebVitals()
