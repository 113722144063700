import { FC } from 'react'
import Carousel from 'react-material-ui-carousel'

import { Typography, Box, useMediaQuery, Theme } from '@mui/material'

import scrollToComponent from 'utils/scrollToComponent'

import Button from 'components/common/Button'

interface Props {
  title: string
  text: string
  buttonText?: string
  carouselItems: { url: string; mobilePosition?: string }[]
}

const LandingCarousel: FC<Props> = ({ title, text, buttonText, carouselItems }) => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Box sx={{ width: '100%', height: '56.25rem' }}>
      <Carousel
        sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '56.25rem', zIndex: 1 }}
        duration={2000}
        interval={4000}
        navButtonsAlwaysInvisible={true}
        stopAutoPlayOnHover={false}
        indicatorIconButtonProps={{
          style: {
            color: '#FFFFFF',
            opacity: '0.5',
            width: '1rem',
            height: '1rem',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            opacity: 1,
          },
        }}
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: '3.75rem',
            zIndex: 15,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '0.5rem',
          },
        }}
      >
        {carouselItems.map((item, key) => (
          <Box
            key={key}
            sx={{
              width: '100%',
              height: '56.25rem',
              // background: {
              //   xs: `linear-gradient(108.26deg, rgba(0, 0, 0, 0.8) 1.74%, rgba(0, 0, 0, 0.6) 17.91%, rgba(0, 0, 0, 0) 74.57%), url(${item.url})`,
              //   sm: `linear-gradient(108.26deg, rgba(0, 0, 0, 0.8) 1.74%, rgba(0, 0, 0, 0.6) 17.91%, rgba(0, 0, 0, 0) 74.57%), url(${item.url})`,
              //   md: `linear-gradient(110.41deg, rgba(0, 0, 0, 0.8) -4.47%, rgba(0, 0, 0, 0.788887) 6.18%, rgba(0, 0, 0, 0) 53.82%), url(${item.url})`,
              // },
              background: `linear-gradient(110.41deg, rgba(0, 0, 0, 0.8) -4.47%, rgba(0, 0, 0, 0.788887) ${
                isSm ? '17.91%' : '6.18%'
              }, rgba(0, 0, 0, 0) ${isSm ? '74.57%' : '53.82%'}), url(${item.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: {
                xs: item?.mobilePosition || 'right',
                sm: item?.mobilePosition || 'right',
                md: 'right',
              },
            }}
          ></Box>
        ))}
      </Carousel>
      <Box
        id="header"
        sx={{
          display: 'flex',
          gap: '3rem',
          pt: { xs: '17.25rem', sm: '17.25rem', md: '17.25rem', lg: '17.25rem', xl: '17.25rem' },
          position: 'relative',
          zIndex: 10,
          maxWidth: '1152px',
          margin: '0 auto',
        }}
      >
        <Box sx={{ maxWidth: '41.375rem', alignSelf: 'center', px: '1.5rem' }}>
          <Typography
            sx={{
              fontSize: { xs: '2.5rem', sm: '3.5rem', md: '3.5rem', lg: '3.5rem', xl: '3.5rem' },
              fontWeight: 900,
              lineHeight: 1.2,
              color: '#ffffff',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: '1.125rem',
              fontWeight: 400,
              mt: '1.5rem',
              textAlign: 'justify',
              color: '#ffffff',
            }}
          >
            {text}
          </Typography>
          {buttonText && (
            <Button
              onClick={(event) => scrollToComponent(event, 'pricing')}
              sx={{
                mt: '3rem',
                width: {
                  xs: '100%',
                  sm: '18.75rem',
                },
                fontSize: '1.25rem',
                fontWeight: '600',
                height: '70px',
                borderRadius: '35px',
                color: '#262626',
                backgroundColor: '#CBFA92',
                '&:hover': {
                  backgroundColor: '#B4F861',
                },
              }}
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default LandingCarousel
