import { Box, Typography } from '@mui/material'

import Modal from 'components/common/Modal'

import { POLICIES_TITLE_STYLES } from './CookiePolicyModal'

interface Props {
  onClose: () => void
}

export const TermsAndConditionsModal = ({ onClose }: Props) => {
  return (
    <Modal
      open
      onClose={onClose}
      title="Shopiview Terms and Conditions"
      titleAlign="left"
      titleStyles={{
        p: '1.5rem',
        fontWeight: 700,
      }}
      sx={{ maxWidth: '74.125rem', gap: 0 }}
    >
      <Modal.Content
        sx={{
          p: '0 1.5rem 1.5rem',
        }}
      >
        <Typography component="p" sx={{ fontSize: '0.75rem' }}>
          Last Updated: June 1, 2024
        </Typography>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            1. Introduction
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Welcome to Shopiview. This platform (&quot;Platform&quot;) is operated and owned by We
            Can Develop IT LLC. Head Office: We Can Develop IT LLC, 12000 Ancient Crest Circle, Apt
            12107, Franklin, Tennessee, 37067. By accessing or using Shopiview, you agree to comply
            with and be bound by these Terms and Conditions (&quot;Terms&quot;).
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            2. Services
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Shopiview provides a platform where users can leave paid reviews of products on various
            marketplaces. Users earn rewards for useful reviews, while companies gain access to
            quality and unbiased feedback.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            3. Account Registration
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.1. To use Shopiview, you must register and create an account.
          </Typography>

          <Typography component="p" sx={{ ml: '1rem' }}>
            3.2. You agree to provide accurate and complete information during the registration
            process.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.3. You are responsible for maintaining the confidentiality of your account information
            and for all activities that occur under your account.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            4. User Conduct
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.1. You must be at least 18 years old to use Shopiview.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.2. You agree to use the Platform for lawful purposes only and not to engage in any
            activity that could harm Shopiview or its users.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.3. Reviews must be genuine and based on your actual experience with the product.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            5. Payment and Rewards
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            5.1. Users will be rewarded for reviews according to the payment structure outlined on
            the Platform.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            5.2. Payments will be made through the payment methods specified by Shopiview.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            5.3. Shopiview reserves the right to withhold payment for any reviews that violate these
            Terms or are found to be fraudulent.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            6. Content Ownership and Rights
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            6.1. By submitting a review, you grant Shopiview a non-exclusive, worldwide,
            royalty-free license to use, reproduce, and distribute your content.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            6.2. You retain ownership of your content but grant Shopiview the right to modify and
            display it on the Platform and related promotional materials.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            7. Privacy
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            7.1. Shopiview collects and uses your personal information in accordance with its
            Privacy Policy.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            7.2. By using the Platform, you consent to the collection and use of your information as
            described in the Privacy Policy.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            8. Termination
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            8.1. Shopiview reserves the right to terminate or suspend your account at any time for
            any reason, including but not limited to violations of these Terms.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            8.2. Upon termination, your right to use the Platform will immediately cease.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            9. Limitation of Liability
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            9.1. Shopiview is provided &quot;as is&quot; without warranties of any kind.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            9.2. We are not liable for any indirect, incidental, or consequential damages arising
            from your use of the Platform.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            10. Changes to Terms
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            10.1. Shopiview may update these Terms from time to time. The updated Terms will be
            posted on the Platform with the effective date.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            10.2. Your continued use of the Platform after any changes indicates your acceptance of
            the new Terms.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            11. Contact Information
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            For any questions about these Terms, please contact us at:
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            We Can Develop IT LLC
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            12000 Ancient Crest Circle, Apt 12107
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Franklin, Tennessee, 37067
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Email: support@shopiview.com
          </Typography>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
