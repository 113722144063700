import { baseApi } from 'api/api'

import { WaitListRecord } from './types'

export const publicApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    waitList: build.mutation<void, WaitListRecord>({
      query: (data) => ({
        url: `/waitlist`,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useWaitListMutation } = publicApi
