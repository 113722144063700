import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from '@mui/material'
import Box from '@mui/material/Box'
import { FC } from 'react'
import { Controller } from 'react-hook-form'

interface TextInputProps extends FormControlLabelProps {
  control: any
  name: string
  errors?: any
  size?: 'medium' | 'small'
}

const CustomCheckbox: FC<TextInputProps> = ({
  control,
  name,
  label,
  errors,
  size,
  ...rest
}) => {
  const error=errors?.[name]
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Controller
        name={name}
        control={control}
        defaultValue={false}
        render={({ field: { value, onChange } }) => (
          <FormControl error={Boolean(error)}>
            <FormControlLabel
              label={label}
              color='red'
              {...rest}
              control={
                <Checkbox
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  color='primary'
                  size={size}
                />
              }
            />
            {error?.message && (
              <FormHelperText>{error?.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  )
}

export default CustomCheckbox
