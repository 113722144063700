import { FC, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box/Box'
import InputAdornment from '@mui/material/InputAdornment/InputAdornment'
import TextField, { StandardTextFieldProps } from '@mui/material/TextField'

interface TextInputProps extends StandardTextFieldProps {
  control: Control<any>
  name: string
  label?: string
  width?: string
  errors?: any
  customErrorMessage?: string
  borderRadius?: string | number
  onBlurHandler?: () => void
}

const TextInput: FC<TextInputProps> = ({
  control,
  errors,
  name,
  label,
  width = '100%',
  customErrorMessage,
  autoFocus,
  onBlurHandler,
  disabled,
  size = 'medium',
  type,
  sx,
  borderRadius = 1,
  ...rest
}) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(true)
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name, ref, onBlur } }) => (
        <TextField
          {...rest}
          onChange={onChange}
          value={value || ''}
          name={name}
          inputRef={ref}
          label={label}
          onBlur={onBlurHandler ?? onBlur}
          autoFocus={autoFocus}
          error={!!errors[name]}
          helperText={customErrorMessage ? customErrorMessage : errors[name]?.message}
          size={size}
          disabled={disabled}
          type={type === 'password' ? (isPasswordHidden ? 'password' : 'text') : type}
          InputProps={{
            endAdornment:
              type === 'password' ? (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      display: 'flex',
                      borderLeft: '1px solid rgba(0,0,0,.54)',
                      pl: '1rem',
                      py: '0.3rem',
                    }}
                    onClick={() => setIsPasswordHidden((prev) => !prev)}
                  >
                    {isPasswordHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </Box>
                </InputAdornment>
              ) : undefined,
          }}
          sx={{
            height: '100%',
            width: width,
            borderRadius: borderRadius,
            '& .Mui-error.MuiFormLabel-root': {
              color: 'primary.text',
            },
            ...sx,
          }}
        />
      )}
    />
  )
}

export default TextInput
