import { Box } from '@mui/material'
import Typography from '@mui/material/Typography/Typography'

import Modal from 'components/common/Modal'

import { POLICIES_TITLE_STYLES } from './CookiePolicyModal'

interface Props {
  onClose: () => void
}

export const PrivacyPolicyModal = ({ onClose }: Props) => {
  return (
    <Modal
      open
      onClose={onClose}
      title="Shopiview Privacy Policy"
      titleAlign="left"
      titleStyles={{
        p: '1.5rem',
        fontWeight: 700,
      }}
      sx={{ maxWidth: '74.125rem', gap: 0 }}
    >
      <Modal.Content
        sx={{
          p: '0 1.5rem 1.5rem',
        }}
      >
        <Typography component="p" sx={{ fontSize: '0.75rem' }}>
          Last Updated: June 1, 2024
        </Typography>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            1. Introduction
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Welcome to Shopiview. This Privacy Policy describes how We Can Develop IT LLC
            (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses, and shares your
            personal information when you use our platform (&quot;Platform&quot;).
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            2. Information We Collect
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            2.1. Personal Information: When you create an account, we collect information such as
            your name, email address, and payment details.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            2.2. Usage Data: We collect information about your interactions with the Platform,
            including the pages you visit and the actions you take.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            2.3. Cookies and Tracking Technologies: We use cookies and similar tracking technologies
            to collect information about your use of the Platform and to enhance your user
            experience.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            3. How We Use Your Information
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.1. To Provide and Improve Our Services: We use your information to operate, maintain,
            and improve the Platform.
          </Typography>

          <Typography component="p" sx={{ ml: '1rem' }}>
            3.2. To Process Payments: We use your payment information to process transactions and
            compensate you for reviews.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.3. To Communicate with You: We use your contact information to send you updates,
            promotional materials, and other information related to your use of the Platform.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            3.4. To Ensure Security and Compliance: We use your information to detect and prevent
            fraud, abuse, and violations of our Terms and Conditions.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            4. How We Share Your Information
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.1. With Service Providers: We may share your information with third-party service
            providers who help us operate the Platform and provide our services.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.2. With Business Partners: We may share your information with business partners for
            marketing and promotional purposes, but only with your consent.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            4.3. For Legal Reasons: We may disclose your information if required by law or in
            response to a legal request, such as a subpoena, court order, or government demand.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            5. Data Security
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            5.1. Security Measures: We implement appropriate security measures to protect your
            personal information from unauthorized access, alteration, disclosure, or destruction.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            5.2. Data Breach Response: In the event of a data breach, we will notify you and the
            appropriate authorities as required by law.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            6. Your Rights
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            6.1. Access and Correction: You have the right to access and correct your personal
            information. You can update your account information through the Platform.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            6.2. Deletion: You have the right to request the deletion of your personal information,
            subject to certain legal exceptions.
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            6.3. Opt-Out: You can opt-out of receiving promotional emails from us by following the
            unsubscribe instructions in those emails.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            7. Children&apos;s Privacy
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            The Platform is not intended for use by individuals under the age of 18. We do not
            knowingly collect personal information from children. If we become aware that we have
            collected personal information from a child, we will take steps to delete such
            information.
          </Typography>
        </Box>
        <Box>
          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            8. Changes to This Privacy Policy
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            We may update this Privacy Policy from time to time. The updated Privacy Policy will be
            posted on the Platform with the effective date. Your continued use of the Platform after
            any changes indicates your acceptance of the new Privacy Policy.
          </Typography>

          <Typography component="h3" sx={POLICIES_TITLE_STYLES}>
            9. Contact Information
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            If you have any questions about this Privacy Policy, please contact us at:
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            We Can Develop IT LLC
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            12000 Ancient Crest Circle, Apt 12107
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Franklin, Tennessee, 37067
          </Typography>
          <Typography component="p" sx={{ ml: '1rem' }}>
            Email: support@shopiview.com
          </Typography>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
