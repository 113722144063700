export const landingPricesText = [
  {
    level: 'Free',
    sum: '0',
    sale: false,
    subscriptionType: 1,
    text: [
      {
        subtitle: 'Basic Integration:',
        details: 'Install video plugin at Shopify store.',
      },
      {
        subtitle: 'Rewards Budget:',
        details: 'Receive $100 to reward reviewers (limited to $100 total).',
      },
      {
        subtitle: 'Shop Management:',
        details: 'Manage up to 1 shops.',
      },
      {
        subtitle: 'Support: ',
        details: 'Limited customer support.',
      },
    ],
  },
  {
    level: 'Standard',
    sum: '15',
    sale: true,
    subscriptionType: 2,
    text: [
      {
        subtitle: 'Enhanced Integration:',
        details: 'All features of the Free Plan, plus.',
      },
      {
        subtitle: 'Expanded Shop Management:',
        details: 'Manage up to 3 shops.',
      },
      {
        subtitle: 'Priority Support: ',
        details: 'Advanced customer support.',
      },
      {
        subtitle: 'Flexible Rewards:',
        details:
          'Ability to issue additional rewards to reviewers beyond the initial $100 - up to $300',
      },
      {
        subtitle: 'Review Notifications:',
        details: 'Receive email notifications for new reviews.',
      },
    ],
  },
  {
    level: 'Pro',
    sum: '30',
    sale: true,
    subscriptionType: 3,
    text: [
      {
        subtitle: 'Comprehensive Integration:',
        details: 'All features of the Standard Plan, plus.',
      },
      {
        subtitle: 'Unlimited Shop Management:',
        details: 'Manage an unlimited amount of shops.',
      },
      {
        subtitle: 'Advanced Security: ',
        details: 'Enhanced security features.',
      },
      {
        subtitle: 'Coupon History Tracking: ',
        details: 'Ability to track coupons history.',
      },
    ],
  },
]

export const allPrices = [
  {
    level: 'Free Unlimited',
    sum: '0',
    sale: false,
    subscriptionType: 0,
    text: [
      {
        subtitle: 'All possible features are free for you.',
        details: undefined,
      },
    ],
  },
  ...landingPricesText,
]
