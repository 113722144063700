import { FC, ReactNode } from 'react'

import { Button as MuiButton, ButtonProps } from '@mui/material'
import Box from '@mui/material/Box'

interface Props extends ButtonProps {
  children?: string | ReactNode
  loading?: boolean
  borderRadius?: string | number
}

const Button: FC<Props> = ({
  loading,
  type = 'button',
  children,
  variant = 'contained',
  disabled,
  borderRadius = '0.5rem',
  ...rest
}) => {
  return (
    <MuiButton
      disabled={disabled || loading}
      type={type}
      variant={variant}
      size="medium"
      disableRipple={variant === 'text'}
      sx={{ borderRadius: borderRadius }}
      {...rest}
    >
      <Box
        sx={{
          display: 'flex',
          height: '1.25rem',
          alignItems: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Box>
    </MuiButton>
  )
}

export default Button
