import { string, object, boolean } from 'yup'

export const requiredMessage = 'This field is required'

const phoneRegExp = /^[+]?[0-9]{10,15}$/
const mailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const waitListSchema = object({
  name: string().required(requiredMessage),
  email: string().matches(mailRegExp, 'Invalid Email Format').required(requiredMessage),
  phone: string()
    .matches(phoneRegExp, 'Invalid Phone Number Format')
    .nullable()
    .transform((v) => (v ? v : null)),
  terms: boolean().oneOf([true], requiredMessage),
})
