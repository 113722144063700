import { FC, useEffect } from 'react'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'

import { Box } from '@mui/material'

import KeycloakService from 'services/KeycloakService'

import PublicAppBar from './PublicAppBar'
import PublicFooter from './PublicFooter'

const PublicLayout: FC = () => {
  const { search } = useLocation()
  const [, setSearchParams] = useSearchParams()
  useEffect(() => { //checks is user come to site after email verification
    if (search.includes('afterVerification=true')) {
      setSearchParams('')
      KeycloakService.doLogin()
    }
  }, [search])
  return (
    <Box
      sx={{ minHeight: '100vh' }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <PublicAppBar />
      <Outlet />
      <PublicFooter />
    </Box>
  )
}

export default PublicLayout
